import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./menu.css";
import Item from "./item";
import data from "./menu.csv";

import img1 from "./img/image-1.png";
import img2 from "./img/image-2.png";
import img3 from "./img/image-3.png";
import img4 from "./img/image-4.png";
import img5 from "./img/image-5.png";
import img6 from "./img/image-6.png";
import img7 from "./img/image-7.png";
import img8 from "./img/image-8.png";
import img9 from "./img/image-9.png";
import img10 from "./img/image-10.png";
import img11 from "./img/image-11.png";
import img12 from "./img/image-12.png";
import img13 from "./img/image-13.png";

const categoryImages = {
  "SUPPEN": img1,
  "ANTIPASTI INSALATE": img2,
  "HEISSGERICHTE": img3,
  "PIZZEN": img4,
  "SPAGHETTI": img5,
  "RISOTTO": img6,
  "TORTELLINI": img7,
  "TAGLIATELLE": img8,
  "MENÜS": img9,
  "BURGERS": img10,
  "DESSERTS": img11,
  "INDISCH CURRY": img12,
  "GETRÄNKE": img13,

};

const Menu = ({ category }) => {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Read and parse CSV file into array
    Papa.parse(data, {
      download: true,
      header: true,
      delimiter: ';', // specify the delimiter here
      complete: function (results) {
        setMenuData(results.data);
        setLoading(false);
      },
    });
  }, []);


  const filteredItems = menuData.filter(
    (item) => item.Category === category
  );

  if (loading) {
    return <p>Loading...</p>;
  }
  else

    return (
      <div class="parent">
        <img class="image" src={categoryImages[category]} alt="image"  />
        <div class="food-class">{category}</div>
        <div class="menuu">
          {filteredItems.map((item, index) => (
            <Item
              key={index}
              itemName={item.Item}
              itemCode={item.Ingredients}
              itemPrice={`€ ${item.Price}`}
            />
          ))}
        </div>
      </div>
    );
};

export default Menu;
