import React, { useEffect, useRef } from 'react';
import logo from './img/logo.svg';
import './App.css';
import Item from './item';
import Hero from './hero';
import Menu from './menu';
import Nav from './nav';
import phone from './img/phone.png';
import Footer from './footer';
import './styleguide.css';


function App() {
  useEffect(() => {
    document.title = "Bellini Food Express";
  }, []);
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);

  const refs = {
    Ref1,
    Ref2,
    Ref3,
    Ref4,
    Ref5,
    Ref6,
    Ref7,
    Ref8,
    Ref9,
    Ref10,
    Ref11,
    Ref12,
    Ref13,
  };

  return (
    <div>
      <div className="App">
        <Hero />
        <div class="menu-heading">Speisekarte</div>
        <Nav refs={refs} />
        <div ref={Ref1}>
          <Menu category="SUPPEN" />
        </div>
        <div ref={Ref2}>
          <Menu category="ANTIPASTI INSALATE" />
        </div>
        <div ref={Ref3}>
          <Menu category="HEISSGERICHTE" />
        </div>
        <div ref={Ref4}>
          <Menu category="PIZZEN" />
        </div>
        <div ref={Ref5}>
          <Menu category="SPAGHETTI" />
        </div>
        <div ref={Ref6}>
          <Menu category="RISOTTO" />
        </div>
        <div ref={Ref7}>
          <Menu category="TORTELLINI" />
        </div>
        <div ref={Ref8}>
          <Menu category="TAGLIATELLE" />
        </div>
        <div ref={Ref9}>
          <Menu category="MENÜS" />
        </div>
        <div ref={Ref10}>
          <Menu category="BURGERS" />
        </div>
        <div ref={Ref11}>
          <Menu category="DESSERTS" />
        </div>
        <div ref={Ref12}>
          <Menu category="INDISCH CURRY" />
        </div>
        <div ref={Ref13}>
          <Menu category="GETRÄNKE" />
        </div>




      </div>
      <button className="quick-button" onClick={() => window.location.href = "tel:06766816400"}>
        <img className="phone-icon" alt="" src={phone} />
        <div className="textt">Jetzt Bestellen</div>
      </button>
      <Footer />
    </div>
  );
}

export default App;
