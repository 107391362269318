import React from "react";
import "./hero.css";
import phone from "./img/phone.png";
import blogo from "./img/brand-logo.png";


const Hero = () => {
  return (
    <div className="hero-container">
      {/* <div className="brand-name">
      { <img className="logo" alt="" src={logo} />}
        <p className="brand-main"><span>Bellini</span><br/>Food Express</p>
      </div> */} 
      <img className="blogo" alt="" src={blogo} />
      <div className="address">Saint-Julien Str 7A<br/>5020 Salzburg</div>
      <a className="email" href="mailto:foodexpress.salzburg@yahoo.com">foodexpress.salzburg@yahoo.com</a>
        
      <button className="contact-button" onClick={() => window.location.href = "tel:06766816400"}>
        <img className="phone-icon" alt="" src={phone} />
        <div className="text">Jetzt Bestellen</div>
      </button>
    </div>
  );
};

export default Hero;
