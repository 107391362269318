import React from "react";
import "./item.css";

const Item = ({ itemName, itemCode, itemPrice }) => {
  return (
    <div className="item-container">
      <div className="item-details">
        <div className="item-name">{itemName}</div>
        <div className="item-code">{itemCode}</div>
      </div>
      <div className="item-price">{itemPrice}</div>
    </div>
  );
};

export default Item;
