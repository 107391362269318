// Footer.js
import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p className="addresss">Saint-Julien Str 7A<br/>5020 Salzburg</p>
      <a className="emaill" href="mailto:foodexpress.salzburg@yahoo.com">foodexpress.salzburg@yahoo.com</a>

      <p>&copy; 2024   Bellini Food Express</p>
    </footer>
  );
}

export default Footer;