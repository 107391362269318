import React, { useRef } from "react";
import "./nav.css";

const Pill = ({ cat, scrollRef }) => {
  const handleClick = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <button className="pill" onClick={handleClick}>
      <div className="cat">{cat}</div>
    </button>
  );
};

const Nav = ({ refs }) => {
  return (
    <div className="nav">
      <Pill cat="SUPPEN" scrollRef={refs.Ref1} />
      <Pill cat="ANTIPASTI INSALATE" scrollRef={refs.Ref2} />
      <Pill cat="HEISSGERICHTE" scrollRef={refs.Ref3} />
      <Pill cat="PIZZEN" scrollRef={refs.Ref4} />
      <Pill cat="SPAGHETTI" scrollRef={refs.Ref5} />
      <Pill cat="RISOTTO" scrollRef={refs.Ref6} />
      <Pill cat="TORTELLINI" scrollRef={refs.Ref7} />
      <Pill cat="TAGLIATELLE" scrollRef={refs.Ref8} />
      <Pill cat="MENÜS" scrollRef={refs.Ref9} />
      <Pill cat="BURGERS" scrollRef={refs.Ref10} />
      <Pill cat="DESSERTS" scrollRef={refs.Ref11} />
      <Pill cat="INDISCH CURRY" scrollRef={refs.Ref12} />
      <Pill cat="GETRÄNKE" scrollRef={refs.Ref13} />
    </div>
  );
};

export default Nav;
